import React, { useEffect } from 'react'
import { Button } from 'reactstrap'

import { MedicalImage_medical_images_by_pk_predictions_normalizeds } from '../../hasura/graphQlQueries/types/MedicalImage'

interface Step {
  label: string
  options: string[]
}

interface KeyValuesSettings {
  steps: Step[]
  none: { [key: string]: string }
}

interface Props {
  settings?: KeyValuesSettings
  save: (result: [string, string[]][]) => void
  medicalImageId?: number
  prediction?: MedicalImage_medical_images_by_pk_predictions_normalizeds
}

export default function KeyValuesForm(props: Props) {
  const [result, setResult] = React.useState<[string, string[]][]>([])

  useEffect(() => {
    if (props.prediction?.key_values_json) {
      setResult(Object.keys(props.prediction.key_values_json).map((k) => [k, props.prediction!.key_values_json[k]]))
    } else {
      setResult([])
    }
  }, [props.prediction, props.medicalImageId])

  if (!props.settings) return null

  const { none, steps } = props.settings

  const handleClick = (label: string, option: string) => {
    const newResult = [...result]
    const index = newResult.findIndex((r) => r[0] === label)

    if (index === -1) {
      newResult.push([label, [option]])
    } else {
      if (newResult[index][1].includes(option)) {
        newResult[index][1] = newResult[index][1].filter((o) => o !== option)
        if (!newResult[index][1].length) newResult.splice(index, 1)
      } else {
        newResult[index][1] = newResult[index][1].concat(option)
      }
    }
    setResult(newResult)
  }

  const allSelected = (steps || []).every((step) => result.findIndex((r) => r[0] === step.label) !== -1)
  const noneSelected = none && result.length && none[0] === result[0][0] && none[1] === result[0][1][0]
  const valid = allSelected || noneSelected

  return (
    <div className="d-flex align-items-start flex-column gap-10px width-fit-content mx-auto z-max-1 mb-4">
      {(steps || []).map((step) => (
        <div key={step.label}>
          <p className="text-dark-bg bold text-uppercase mb-1 text-xs ls-sm m-0">{step.label}</p>

          <div className="d-flex gap-10px">
            {step.options.map((option) => {
              const isSelected = result.find((r) => r[0] === step.label && r[1].includes(option))
              return (
                <Button
                  onClick={() => handleClick(step.label, option)}
                  color={isSelected ? 'success' : 'secondary'}
                  outline={!isSelected}
                  key={option}
                  size="sm"
                >
                  {option}
                </Button>
              )
            })}
          </div>
        </div>
      ))}

      <div className="d-flex justify-content-center mt-3">
        <Button onClick={() => props.save(result)} disabled={!valid} color="success" size="sm">
          Save
        </Button>
      </div>
    </div>
  )
}
